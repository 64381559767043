// constants
import Web3EthContract from 'web3-eth-contract'
import Web3 from 'web3'
// log
import { fetchData } from '../data/dataActions'
import { addBlockchainNetwork } from '../../helpers'

const connectRequest = () => {
  return {
    type: 'CONNECTION_REQUEST'
  }
}

const connectSuccess = (payload) => {
  return {
    type: 'CONNECTION_SUCCESS',
    payload: payload
  }
}

const connectFailed = (payload) => {
  return {
    type: 'CONNECTION_FAILED',
    payload: payload
  }
}

const updateAccountRequest = (payload) => {
  return {
    type: 'UPDATE_ACCOUNT',
    payload: payload
  }
}

export const connect = () => {
  return async (dispatch) => {
    dispatch(connectRequest())
    const abiResponse = await fetch('/config/abi.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
    const tokenAbiResponse = await fetch('/config/tokenAbi.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
    const abi = await abiResponse.json()
    const tokenAbi = await tokenAbiResponse.json()
    const configResponse = await fetch('/config/config.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
    const CONFIG = await configResponse.json()
    const { ethereum } = window
    const metamaskIsInstalled = ethereum && ethereum.isMetaMask
    if (metamaskIsInstalled) {
      Web3EthContract.setProvider(ethereum)
      const web3 = new Web3(ethereum)
      try {
        const accounts = await ethereum.request({
          method: 'eth_requestAccounts'
        })
        const networkId = await ethereum.request({
          method: 'net_version'
        })
        if (networkId === `${CONFIG.NETWORK.ID}`) {
          const NagaSale = new Web3EthContract(
            abi,
            CONFIG.CONTRACT_ADDRESS
          )

          const TokenContract = new Web3EthContract(
            tokenAbi,
            CONFIG.WETH_CONTRACT_ADDRESS
          )

          dispatch(
            connectSuccess({
              account: accounts[0],
              smartContract: NagaSale,
              tokenContract: TokenContract,
              web3: web3
            })
          )
          // Add listeners start
          ethereum.on('accountsChanged', (accounts) => {
            dispatch(updateAccount(accounts[0]))
          })
          ethereum.on('chainChanged', () => {
            window.location.reload()
          })
          // Add listeners end
        } else {
          const isAddBlockchainNetwork = await addBlockchainNetwork()

          if (!isAddBlockchainNetwork) {
            dispatch(connectFailed(`Change network to ${CONFIG.NETWORK.NAME}.`))
          }
        }
      } catch (err) {
        dispatch(connectFailed('Something went wrong.'))
      }
    } else {
      dispatch(connectFailed('Install Metamask.'))
    }
  }
}

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }))
    dispatch(fetchData(account))
  }
}
